<template>
	<Dialog :dialog="dialog" :dialogWidth="dialogWidth" dense>
		<template v-slot:title> Select Asset Service </template>
		<template v-slot:body>
			<v-container fluid class="px-4">
				<template>
					<v-row>
						<v-col md="12">
							<v-layout>
								<v-flex md11>
									<TextInput
										:disabled="dataLoading"
										:loading="dataLoading"
										v-model="search"
										hide-details
										placeholder="Search..."
									></TextInput>
								</v-flex>
								<v-flex v-if="search">
									<v-btn
										:disabled="dataLoading"
										v-on:click="
											search = null;
											getAssetServices();
										"
										tile
										depressed
										class="mt-3"
										color="red lighten-1 white--text"
									>
										<v-icon>mdi-close</v-icon> Cancel</v-btn
									>
								</v-flex>
								<v-flex>
									<v-btn
										:disabled="dataLoading"
										v-on:click="getAssetServices()"
										tile
										depressed
										class="mt-3"
										color="blue darken-4 white--text"
									>
										<v-icon>mdi-magnify</v-icon> Search</v-btn
									>
								</v-flex>
							</v-layout>
						</v-col>
						<v-col md="12">
							<div>
								<table width="100%">
									<template v-if="dataLoading">
										<tr>
											<td colspan="3" align="center" height="93.75px" class="border-light-grey">
												<div class="py-2">
													<v-progress-circular indeterminate color="blue darken-4"></v-progress-circular>
													<p class="mb-0 mt-2 font-level-3 bold-600">Please wait...</p>
												</div>
											</td>
										</tr>
									</template>
									<template v-else>
										<template v-if="assetServiceList.length">
											<tr
												v-on:click="selectAssetService(data)"
												v-for="(data, index) in assetServiceList"
												:key="index"
												class="cursor-pointer border-light-grey"
											>
												<td class="px-4 py-2">
													<div class="d-flex align-content-start flex-wrap align-start flex-column my-1">
														<Chip
															tooltip
															tooltip-text="Service #"
															small
															:text="data.barcode"
															color="blue darken-4"
														></Chip>
														<Chip
															tooltip
															tooltip-text="Service Type"
															small
															class="mt-1"
															:text="data.service_type_formatted"
															:color="data.service_type == 'on-site' ? 'brown darken-4' : 'blue-grey darken-4'"
														></Chip>
														<Chip
															tooltip
															tooltip-text="Service Status"
															small
															:text="ObjectKey(data, 'status_relation.text', 'N/A')"
															class="mt-1"
															:color="ObjectKey(data, 'status_relation.color', 'N/A')"
														></Chip>
													</div>
												</td>
												<td class="px-4 py-2">
													<div class="d-flex flex-column">
														<div class="font-level-3">
															<span class="bold-600 mr-1">Start:</span
															><ShowValue
																:object="data"
																object-key="actual_start_date_formatted"
																label="Start Date (Actual)"
															></ShowValue>
														</div>
														<div class="font-level-3 mt-1">
															<span class="bold-600 mr-1">End:</span
															><ShowValue
																:object="data"
																object-key="actual_end_date_formatted"
																label="End Date (Actual)"
															></ShowValue>
														</div>
													</div>
												</td>
												<td class="px-4 py-2">
													<div class="d-flex align-center">
														<div>
															<ImageTemplate
																circle
																style="max-width: 50px"
																:src="ObjectKey(data, 'performed_by.image', 'N/A')"
															></ImageTemplate>
														</div>
														<div class="ml-2">
															<p class="mb-0">
																<Chip
																	tooltip
																	tooltip-text="Service By"
																	small
																	:text="data.service_by_formatted"
																	color="blue darken-4 white--text"
																></Chip>
															</p>
															<p class="mb-0 font-level-3">
																{{ ObjectKey(data, "performed_by.display_name", "N/A") }}
															</p>
														</div>
													</div>
												</td>
											</tr>
										</template>
										<template v-else>
											<tr>
												<td colspan="3" align="center" height="93.75px" class="border-light-grey">
													<div class="py-2">
														<p class="mb-0 font-level-3 bold-600">Oops... Nothing Found</p>
													</div>
												</td>
											</tr>
										</template>
									</template>
								</table>
							</div>
							<br />
						</v-col>
					</v-row>
				</template>
			</v-container>
		</template>
		<template v-slot:action>
			<v-btn
				class="mx-2 custom-grey-border custom-bold-button"
				color="blue darken-4 white--text"
				tile
				:disabled="dataLoading"
				depressed
				v-on:click="$emit('close', true)"
			>
				Close
			</v-btn>
		</template>
	</Dialog>
</template>

<script>
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import ImageTemplate from "@/view/components/Image";
import Chip from "@/view/components/Chip";
import ApiService from "@/core/services/api.service";
import ShowValue from "@/view/components/ShowValue";
import { toSafeInteger } from "lodash";
import ObjectPath from "object-path";

export default {
	data() {
		return {
			dataLoading: false,
			assetServiceList: [],
			search: null,
			timeoutLimit: 0,
			timeout: null,
		};
	},
	props: {
		dialog: {
			type: Boolean,
			default: false,
		},
		asset: {
			type: String,
			default: null,
		},
	},
	components: {
		Dialog,
		Chip,
		TextInput,
		ShowValue,
		ImageTemplate,
	},
	watch: {
		dialog(param) {
			if (param) {
				this.getAssetServices();
			}
		},
	},
	methods: {
		getAssetServices() {
			const _this = this;
			clearTimeout(_this.timeout);
			_this.timeout = setTimeout(function () {
				_this.dataLoading = true;
				_this.assetServiceList = [];
				ApiService.query(`asset/${_this.asset}/acknowledged-services`, { search: _this.search })
					.then(({ data }) => {
						_this.assetServiceList = data;
					})
					.catch((error) => {
						_this.$emit("error", error);
						_this.logError(error);
					})
					.finally(() => {
						_this.dataLoading = false;
					});
			}, _this.timeoutLimit);
		},
		selectAssetService(row) {
			this.$emit("select", {
				asset: ObjectPath.get(row, "asset_relation.uuid"),
				service: ObjectPath.get(row, "uuid"),
			});
			this.$emit("close", true);
		},
	},
	computed: {
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 50);
		},
	},
};
</script>
